import React, { useEffect, useState } from "react";
import "./About.css";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { FaArrowRight } from "react-icons/fa6";
export default function About() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === 2 ? 0 : prevSlide + 1));
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className="about-section">
        <div className="about-container">
          <img className="about-image" src="./Assets/about-image.png"></img>
          <div className="about-text">
            <h1 className="about-text">About Us</h1>
            <h2 className="about-text1">
              We're your Virtual Photo Editing & Design Studio
            </h2>
            <p className="about-text2">
              At Zeus, we transcened the ordinary to redefine visual excellence.
              Born out of a person for turning ordinary images into
              extraordinary masterpieces.Zeus is more than just a photo editing
              service -it's a creative hub where innovation meets expertise. Our
              dedicated team of editors is committed to perfecting your
              moments,ensuring each image tells a unique and compelling story.
            </p>
            <p className="about-text2">
              Join us in shaping a world where every image speaks volumes, and
              let Zeus transform your visual narrative with unparralleled
              creativity and craftsmanship.
            </p>
            {/* <button className="btn">
            Read More{" "}
            <img className="right-arrow" src="./Assets/right-arrow.png"></img>
          </button> */}
          </div>
        </div>
        <div className="service-section">
          <h1 className="service-text">Our Services</h1>
          <div className="service-container">
            <h2 className="service-text1">
              Our Most Popular Photo Editing Services
            </h2>
          </div>
          <div className="profile-slider-container">
            <div
              className="our-new-slider-section"
              style={{ transform: `translateX(-${currentSlide * 33.33}%)` }}
            >
              <div
                className=""
                style={{ backgroundColor: "red", height: "20vh" }}
              ></div>
              <img
                className="image-our-section-grid"
                src="/Assets/shoue.png"
              ></img>
              <div className="our-new-slider-text">
                <h4>Image Masking</h4>
                <p style={{padding:'0px 10px'}}>
                Image masking is a digital editing technique that isolates parts of an image for precise manipulation and enhancement.
                </p>
              </div>
              <div className="circle">
                <FaArrowRight style={{ fontSize: "20px", color: "white" }} />
              </div>
            </div>
            <div
              className="our-new-slider-section"
              style={{ transform: `translateX(-${currentSlide * 33.33}%)` }}
            >
              <div
                className=""
                style={{ backgroundColor: "#85a2f2", height: "20vh" }}
              ></div>

              <img
                className="image-our-section-grid"
                src="/Assets/shoue1.png"
              ></img>
              <div className="our-new-slider-text">
                <h4>Photo Clipping</h4>
                <p style={{padding:'0px 10px'}}>
                Photo clipping is a technique used to precisely cut out objects from their backgrounds in images.
                </p>
              </div>
              <div className="circle">
                <FaArrowRight style={{ fontSize: "20px", color: "white" }} />
              </div>
            </div>
            <div
              className="our-new-slider-section"
              style={{ transform: `translateX(-${currentSlide * 33.33}%)` }}
            >
              <div
                className=""
                style={{ backgroundColor: "#0aecf0", height: "20vh" }}
              ></div>
              <img
                className="image-our-section-grid"
                src="/Assets/shoue.png"
              ></img>
              <div className="our-new-slider-text">
                <h4>Video Editing</h4>
                <p style={{padding:'0px 10px'}}>
                Video editing transforms raw footage into a polished narrative through manipulation, effects, and audio integration.
                </p>
              </div>
              <div className="circle">
                <FaArrowRight style={{ fontSize: "20px", color: "white" }} />
              </div>
            </div>
          </div>
        </div>
        <div className="choose-section">
          <img className="choose-image" src="./Assets/choose-image.png"></img>
          <div className="choose-container">
            <h1 className="choose-text">Why Choose Us</h1>
            <h2 className="choose-text">Why we are special</h2>
            <div className="choose-image-text">
              <img className="choose-icons" src="./Assets/alaram.png"></img>
              <div className="">
                <p className="choose-icon-text">Quick Results</p>
                <p className="choose-text1">
                  At Zeus, we seamlessly blend speed and precision to deliver
                  stunning edits swiftly,ensuring your perfected images are in
                  your hands without delay.
                </p>
              </div>
            </div>
            <div className="choose-image-text">
              <img className="choose-icons" src="./Assets/scan.png"></img>
              <div className="">
                <p className="choose-icon-text">Increased Resolution</p>
                <p className="choose-text1">
                  We not only enhance your images but also deliver results with
                  increase resolution,ensuring every details is magnified to its
                  full brilliance.
                </p>
              </div>
            </div>
            <div className="choose-image-text">
              <img className="choose-icons" src="./Assets/gallary.png"></img>
              <div className="">
                <p className="choose-icon-text">Retain Details</p>
                <p className="choose-text1">
                  We pride ourselves on retaining the intricate details of your
                  images,ensuring every pixel is meticulously preserved to
                  deliver a final masterpiece that captures the essence of your
                  vision.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="feedback-section">
          <h1 className="feed-text">Clients Feedback</h1>
          <br></br>
          <p className="feed-text1">Explore Clients Review</p>
        </div>
        <div className="profile-slider-container">
          <div
            className="profile-slider-section"
            style={{ transform: `translateX(-${currentSlide * 33.33}%)` }}
          >
            <img src="./Assets/star.png" alt="Star" />
            <h4>Awesome Work</h4>
            <p className="Testimonials-text">
              Zeus not only met but exceeded my expectations! Their attention to
              deal and commitment to perfection truly set them apart in the
              world of photo edting.
            </p>
            <div className="profile-slider-text">
              <CgProfile style={{ fontSize: "30px" }} />
              <p className="profile-slider-text1">Aditya Vikram Singh</p>
            </div>
          </div>
          <div
            className="profile-slider-section"
            style={{ transform: `translateX(-${currentSlide * 33.33}%)` }}
          >
            <img src="./Assets/star.png" alt="Star" />
            <h4>Wonderful</h4>
            <p className="Testimonials-text">
              Zeus not only met but exceeded my expectations! Their attention to
              deal and commitment to perfection truly set them apart in the
              world of photo edting.
            </p>
            <div className="profile-slider-text">
              <CgProfile style={{ fontSize: "30px" }} />
              <p className="profile-slider-text1">Rahul Mishra</p>
            </div>
          </div>
          <div
            className="profile-slider-section"
            style={{ transform: `translateX(-${currentSlide * 33.33}%)` }}
          >
            <img src="./Assets/star.png" alt="Star" />
            <h4>Amazing</h4>
            <p className="Testimonials-text">
              Zeus not only met but exceeded my expectations! Their attention to
              deal and commitment to perfection truly set them apart in the
              world of photo edting.
            </p>
            <div className="profile-slider-text">
              <CgProfile style={{ fontSize: "30px" }} />
              <p className="profile-slider-text1">Prasenjeet Nath</p>
            </div>
          </div>
        </div>

        <div className="contact">
          <h1 className="tel-text">You Have Any Projects In your Mind?</h1>

          {/* <div className="tel-container">
            <FaPhoneSquareAlt style={{ fontSize: "30px", color: "white" }} />
            <h1 className="tel-text1">+91-9123455788</h1>
          </div> */}
        </div>
      </div>
    </>
  );
}
