import React from 'react'

export default function PrivacyPolicy() {
  return (
    <>
      <div
        className="nav-service-section"
        style={{ backgroundColor: "black", color: "#FFFFFF" }}
      >
        <h1 style={{ textAlign: "center", padding: "36px 0px" }}>
          Privacy Policy
        </h1>
        <p>
        Zeus is committed to upholding all privacy principles to safeguard your information. Our privacy statement outlines how your information is shared with us and how we process it securely. We utilize your Personal Data and other personally non-identifiable information collected through the Website to enhance the relevance and functionality of the Website, better understand our clients, and improve our services. Terms used in our Privacy Policy are defined in the Definitions section below.
        </p>
        <div className="nav-service-container">
          <div className="nav-service-container1">
            <h3>1. Information We Collect :</h3>
            <p>
            When you fill out our contact form, we request information such as your Name, Email Id, Phone Number, Website Link, Country, and other details. The information collected from our clients is stored in secure and reliable databases. We assure you that we never share your personal data with any third party.
            </p>
            <h3>2. Collection of Computer Information :</h3>
            <p>
            When our customers access our website through a browser or other means, our automated systems collect computer information. By interacting with our website via web browsers and mobile devices, we gather information such as IP address, website provider name, log data, cookies, type of mobile devices, operating system, location data, search queries, etc.
            </p>
            <h3>3. Security :</h3>
            <p>
            The personal information of our clients is of utmost importance to us, as we understand the value of your data. Therefore, we have implemented 100% secure databases to safeguard your information. We employ practical security measures to protect against loss, misuse, and alteration of the Personal Data under our control. 
            </p>
            <p>
            Personal Data is stored in a secured database and transmitted via encrypted SSL method when supported by your web browser. However, please note that no Internet or email transmission is ever completely guaranteed.
            </p>
            <h3>5. Use of Informations :</h3>
            <p>
            We use the information collected for various purposes, including but not limited to:
            </p>
          </div>
        </div>
        <div className="nav-service-container" style={{paddingBottom:'36px'}}>
          <div className="nav-service-texts">
            <li className="nav-service-text">
            Providing and improving our services.
            </li>
            <li className="nav-service-text">
            Communicating with you about our services and promotions.
            </li>
            <li className="nav-service-text">
            Customizing your experience on our website.
            </li>
            <li className="nav-service-text">
            Analyzing trends and user behavior to enhance our offerings.
            </li>
            <li className="nav-service-text">
            Fulfilling legal and regulatory requirements
            </li>
          </div>
        </div>
      </div>
    </>
  )
}
