import React from 'react'

export default function Panorama() {
  return (
    <>
      <div
        className="nav-service-section"
        style={{ backgroundColor: "black", color: "#FFFFFF" }}
      >
        <h1 style={{ textAlign: "center", padding: "36px 0px" }}>
          360 Panorama Services
        </h1>
        <p>
        A 360 panorama is a wide-angle view of a physical space that captures a 360-degree field around a single point. This kind of image provides a comprehensive view that can simulate the experience of standing in a particular location and looking around in every direction. Here’s a breakdown of what 360 panoramas are, how they're created, and their various applications:
        </p>
        <div className="nav-service-container">
          <div className="nav-service-container1">
            <h2>What is a 360 Panorama?</h2>
            <h3>1. 360-degree Photography :</h3>
            <p>
            This is a photographic technique where multiple images are taken from the same fixed point and stitched together to form a single, continuous image that covers a full 360-degree view horizontally, and sometimes vertically as well.
            </p>
            <h3>2. Interactive Panoramas :</h3>
            <p>
            Most 360 panoramas allow viewers to interact with the image, rotating it left or right, up or down, giving the feeling of looking around in real space. This can be experienced via web browsers, VR headsets, or through apps on smartphones and tablets.
            </p>
            <h3>3. Camera Needed :</h3>
            <p>
            Any camera can be used, but some are specially designed for 360 photography, like the Ricoh Theta, GoPro Fusion, and Insta360 cameras.
            </p>
            <h3>4. Tripod :</h3>
            <p>
            To keep the camera stable and ensure that all images are taken from the same point
            </p>
            <h3>5. Rotator or Pano Head :</h3>
            <p>
            These help in precisely positioning the camera for each shot, essential for smooth stitching.
            </p>
          </div>
          <div className="service-container2">
            <img className="nav-service-img" src="./Assets/panoram1.avif"></img>
          </div>
        </div>
        <h2 className="nav-service-h2">
        Applications of 360 Panoramas:
        </h2>
        <div className="nav-service-container">
          <img className="nav-service-container-images" src="./Assets/panoram.avif"></img>
          <div className="nav-service-texts">
            <li className="nav-service-text">
            Virtual Tours: Real estate, museums, and tourist destinations often use 360 panoramas to offer virtual tours to visitors online.
            </li>
            <li className="nav-service-text">
            Video Games and VR: Provides immersive environments that players can explore by simply looking around.
            </li>
            <li className="nav-service-text">
            Event Coverage: Offers viewers a virtual experience of being at events like concerts, sports, or festivals.
            </li>
            <li className="nav-service-text">
            Education and Training: Useful for creating realistic environments where learners can practice skills or explore places they cannot physically visit.
            </li>
          </div>
        </div>
        <div className="nav-service-container-last">
          <div>
            <p>
            Marketing and Advertising: Businesses use it to engage customers by providing unique views of their premises or products.
            </p>
            <p>
            360 panoramas have greatly impacted photography, tourism, real estate, and many other fields by providing a more immersive and detailed view than traditional photographs. They allow people to explore and interact with spaces remotely, which can be invaluable for education, marketing, and entertainment.
            </p>
          </div>
          <img
            className="nav-service-container-images1"
            src="./Assets/panoram2.avif"
          ></img>
        </div>
      </div>
    </>
  )
}
