import React from 'react'

export default function RealEstate() {
  return (
    <>
    <div
        className="nav-service-section"
        style={{ backgroundColor: "black", color: "#FFFFFF" }}
      >
        <h1 style={{ textAlign: "center", padding: "36px 0px" }}>
        Real Estate Services
        </h1>
        <p>
        Welcome to the world of real estate services, where dreams of owning the perfect home, securing lucrative investments, or finding ideal tenants become reality with the expertise and support of dedicated professionals. Whether you're a first-time buyer, a seasoned investor, or a property owner looking to maximize returns, the realm of real estate services offers a wealth of opportunities and solutions tailored to your needs.
        </p>
        <div className="nav-service-container">
          <div className="nav-service-container1">
            <h2>A Detailed Overview of How We Can Assist You</h2>
            <h3>1. Property Listings and Marketing :</h3>
            <p>
            Real estate agents and agencies provide services to list properties for sale or rent. They use various marketing channels such as online platforms, print media, and networking to reach potential buyers or tenants.
            </p>
            <h3>2. Property Valuation :</h3>
            <p>
            Real estate professionals offer valuation services to determine the market value of properties. Accurate valuation is crucial for sellers to set competitive prices and for buyers to make informed decisions.
            </p>
            <h3>3. Buyer Representation :</h3>
            <p>
            Real estate agents represent buyers in the purchase process. They help clients find suitable properties, negotiate offers, and navigate the complexities of transactions, ensuring a smooth and successful purchase experience.
            </p>
            <h3>4. Seller Representation :</h3>
            <p>
            Sellers enlist the services of real estate agents to represent their interests in selling their properties. Agents assist with pricing strategies, marketing campaigns, staging, showing the property to potential buyers, and negotiating offers.
            </p>
            <h3>5. Property Management :</h3>
            <p>
            For property owners who prefer a hands-off approach, real estate firms offer property management services. This includes handling tenant inquiries, collecting rent, coordinating maintenance and repairs, and ensuring compliance with regulations.
            </p>
          </div>
          <div className="service-container2">
            <img className="nav-service-img" src="./Assets/real estate5.avif"></img>
          </div>
        </div>
        <h2 className="nav-service-h2">
        Investment Consultation:
        </h2>
        <div className="nav-service-container">
          <img className="nav-service-container-images" src="./Assets/real estate1.avif"></img>
          <div className="nav-service-texts">
            <li className="nav-service-text">
            Investment Consultation: Real estate professionals provide investment consultation services to individuals or companies looking to invest in real estate. 
            </li>
            <li className="nav-service-text">
            They offer insights into market trends, identify investment opportunities, and help clients develop investment strategies aligned with their goals and risk tolerance.
            </li>
            <li className="nav-service-text">
            Market Research and Analysis: Real estate agencies conduct market research and analysis to provide clients with valuable insights into local real estate trends, property values, demographic data, and economic indicators.
            </li>
            <li className="nav-service-text">
            This information helps clients make informed decisions about buying, selling, or investing in real estate.
            </li>
          </div>
        </div>
        <div className="nav-service-container-last">
          <div>
            <p>
            Legal and Financial Guidance: Real estate transactions involve legal and financial complexities. Real estate professionals offer guidance on legal requirements, financing options, tax implications, and other financial considerations to ensure clients make sound decisions and comply with regulations.
            </p>
            <p>
            Negotiation Expertise: Real estate agents possess strong negotiation skills to advocate for their clients' interests during price negotiations, contract terms, and other aspects of real estate transactions. Their expertise helps clients achieve favorable outcomes while minimizing risks.
            </p>
          </div>
          <img
            className="nav-service-container-images1"
            src="./Assets/real estate2.avif"
          ></img>
        </div>
      </div>
    </>
  )
}
