import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import About from "../About/About";
export default function Header() {
  const [isOpen, setIsOpen] = useState(true);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };
  const [currentIndex, setCurrentIndex] = useState(0);
    // const images = [
    //     '/assets/photo editing2.avif',
    //     '/assets/Creative Service1.jpg',
    //     '/assets/photo editing1.avif',
    //     '/assets/photo masking1.avif',
    // ];
    const images = [
        '/assets/slider.jpg',
        '/assets/slider1.jpg',
        '/assets/slider2.jpg',
        '/assets/slider3.jpg',
    ];
    useEffect(() => {
      const interval = setInterval(() => {
          setCurrentIndex(current => (current + 1) % images.length);
      }, 3000); 

      return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
    <div className="header-section">
    <div style={{ width: '100%', overflow: 'hidden' }}>
            <img className="image-mobile-response" src={images[currentIndex]} alt="Slideshow" />
        </div>
     
      <div className="header">
        <div className="Header-left">
          <h1 className="header-text">
            Transforming Moments,<br></br>Perfecting Memories:<br></br>Your
            Vision, Our Expertise
          </h1>
          {/* <h2 className='header-text'>Perfecting Memories:</h2>
<h2 className='header-text'>Your Vision, Our Expertise</h2> */}
          <p className="header-text1">
            We're here to turn your vision into a visual masterpiece.Join us in
            the journey of creating unforgottable memories,one pixel at a time.
          </p>
          <div className="btns">
            {/* <button className="btn">
              Free Trial{" "}
              <img className="right-arrow" src="./Assets/right-arrow.png"></img>
            </button>
            <button className="btn1">
              Make a Budget{" "}
              <img className="right-arrow" src="./Assets/right-arrow.png"></img>
            </button> */}
          </div>
        </div>
        <div className="Header-right">
          <img
            className="header-rignt-image"
            src="./Assets/header-rignt-image.png"
          ></img>
        </div>
      </div>
    </div>
    <div>
  <About/>
    </div>
    </>
  );
}
