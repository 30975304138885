import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Service from "./components/Service/Service";
import Navbar from "./components/Navbar/Navbar";
import About from "./components/About/About.js";
import Contact from "./components/Contact/Contact";
import Project from "./components/Projects.js/Project";
import PhotoManipulation from "./components/Service/Components/PhotoManipulation/PhotoManipulation.js";
import RealEstate from "./components/Service/Components/RealEstate/RealEstate.js";
import PhotoIllustration from "./components/Service/Components/PhotoIllustration/PhotoIllustration.js";
import Cliping from "./components/Service/Components/Cliping/Cliping.js";
import PhotoMasking from "./components/Service/Components/Masking/PhotoMasking.js";
import Panorama from "./components/Service/Components/Panorama/Panorama.js";
import Blending from "./components/Service/Components/HDR/Blending.js";
import PrivacyPolicy from "./components/Service/Components/Privacy/PrivacyPolicy.js";
import Disclaimer from "./components/Service/Components/Disclaimer/Disclaimer.js";
import SiteMap from './components/Service/Components/SiteMap/SitMap.js'
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Header />}></Route>
          <Route path="/about" element={<About/>}></Route>
          <Route path="/service" element={<Service />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/project" element={<Project />}></Route>
          <Route
            path="/photomanipulation"
            element={<PhotoManipulation />}
          ></Route>
          <Route path="/realestate" element={<RealEstate />}></Route>
          <Route
            path="/photoillustration"
            element={<PhotoIllustration />}
          ></Route>
          <Route path="/cliping" element={<Cliping />}></Route>
          <Route path="/photomasking" element={<PhotoMasking />}></Route>
          <Route path="/panorama" element={<Panorama />}></Route>
          <Route path="/blending" element={<Blending />}></Route>
          <Route path="/privacy" element={<PrivacyPolicy />}></Route>
          <Route path="/disclaimer" element={<Disclaimer />}></Route>
          <Route path="/sitemap" element={<SiteMap />}></Route>
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
