import React from "react";
import "./Service.css";
export default function Service() {
  return (
    <>
      <div
        className="nav-service-section"
        style={{ backgroundColor: "black", color: "#FFFFFF" }}
      >
        <h1 style={{ textAlign: "center", padding: "36px 0px" }}>
          Photo Editing Services
        </h1>
        <p>
          At Zeus, our Photo Editing Services are crafted to elevate your visual
          content to new heights,ensuring that every image tells a compelling
          and refined story.Our team of skilled editors employs a range of
          professional editing techniques to enhance your pgotos, delivering
          results that surpass your expectations.
        </p>
        <div className="nav-service-container">
          <div className="nav-service-container1">
            <h2>Types of Editing Services we provide</h2>
            <h3>1. color Correction :</h3>
            <p>
              Our color correction services bring vibrancy and balance to your
              images, ensuring accurate and hues that enhance the overall visual
              appeal.
            </p>
            <h3>2. Retouching and Restoration :</h3>
            <p>
              From skin retouching to object removal, our expert retouching
              services provide a flawless finish, restoring and refining your
              images to perfection.
            </p>
            <h3>3. Creative Enhancements :</h3>
            <p>
              Unleash your creativity with our creative enhancement services,
              where we add artistic flair, special effects, and personalized
              touches to make your images truly atand out.
            </p>
            <h3>4. Cropping and Resizing :</h3>
            <p>
              Achieve the perfect composition with our cropping and resizing
              services. Whether it's for social media, print, or specific
              platforms, we tailor your images to fit seamlessly.
            </p>
            <h3>5. Backround Removal :</h3>
            <p>
              Our background removal services allow you to isolate subjects,
              creating a clean and professional look that highlights the main
              elements of your images.
            </p>
          </div>
          <div className="service-container2">
            <img className="nav-service-img" src="./Assets/service-image1.png"></img>
          </div>
        </div>
        <h2 className="nav-service-h2">
          How It Enhances Image Quality:
        </h2>
        <div className="nav-service-container">
          <img className="nav-service-container-images" src="./Assets/service-image2.png"></img>
          <div className="nav-service-texts">
            <li className="nav-service-text">
              Precision and Detail: Our meticulous editing process focuses on
              retaining and enhancing the intricate details of your images,
              ensuring a level of precision that brings out the true essence of
              your visuals.
            </li>
            <li className="nav-service-text">
              Balanced and Natual Results: We strive for natural-looking edits,
              avoiding excessive alterations. Our goal is to maintain the
              authenticity of your photos while enhancing them for a polished
              and professional finish.
            </li>
            <li className="nav-service-text">
              Tailored to Your Vision: We work closely with you to understand
              your vision and preferences, offering a personalized editing
              experience that aligns with your unque style and requirements.
            </li>
            <li className="nav-service-text">
              Improved Visual Storytelling: Through our editing services, we
              empower you to tell a more complelling visual story. Whether it's
              for personal memories, business branding, or creative projects, we
              enhance the narrative within each frame.
            </li>
          </div>
        </div>
        <div className="nav-service-container-last">
          <div>
            <p>
              Having server a diverse clientele, ranging from individuals and
              graphic designers to image-based online businesses and
              professional photographers, we take pride in our proven expertise.
              With our nuanced approach to retouching, we stand at the forefont,
              setting the standard for professional photo retouching services in
              the industry.
            </p>
            <p>
              At Zeus, we understand the power of visual storytelling. Our photo
              editing services are not just about enhancing images;they're about
              creating visual narratives that leave a lasting impact. Join us on
              this journey of transforming your moments into timeless works of
              art.
            </p>
          </div>
          <img
            className="nav-service-container-images1"
            src="./Assets/service-image3.png"
          ></img>
        </div>
      </div>
    </>
  );
}
