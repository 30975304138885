import React, { useState } from "react";
import "./Project.css"; 

const imageData = [
  { id: 1, category: "Image Editing", src: "./Assets/photo editing.avif" },
  { id: 2, category: "Real Estate", src: "./Assets/real estate5.avif" },
  { id: 3, category: "Image Editing", src: "./Assets/photo editing1.avif" },
  { id: 4, category: "Image Editing", src: "./Assets/photo editing2.avif" },
  { id: 5, category: "Image Editing", src: "./Assets/photo editing3.avif" },
  { id: 6, category: "Real Estate", src: "./Assets/real estate.avif" },
  { id: 7, category: "Real Estate", src: "./Assets/real estate1.avif" },
  { id: 8, category: "Real Estate", src: "./Assets/real estate2.avif" },
  { id: 9, category: "Real Estate", src: "./Assets/real estate3.avif" },
  { id: 10, category: "Real Estate", src: "./Assets/real estate4.avif" },
  {
    id: 11,
    category: "Creative Service",
    src: "./Assets/Creative Service.jpg",
  },
  {
    id: 12,
    category: "Creative Service",
    src: "./Assets/Creative Service1.jpg",
  },
  {
    id: 13,
    category: "Creative Service",
    src: "./Assets/Creative Service2.jpg",
  },
  { id: 14, category: "Web Service", src: "./Assets/web service.jpg" },
  { id: 15, category: "Web Service", src: "./Assets/web service1.jpg" },
  { id: 16, category: "Web Service", src: "./Assets/web service2.jpg" },
];

function ImageFilter() {
  const [activeCategory, setActiveCategory] = useState("All");

  const categories = [
    "All",
    ...new Set(imageData.map((item) => item.category)),
  ];

  const handleImageClick = (id) => {
    // Handle image click if needed
    console.log("Clicked image ID:", id);
  };

  const renderImages = () => {
    return activeCategory === "All"
      ? imageData.map((image) => (
          <img
            key={image.id}
            src={image.src}
            alt=""
            style={{ width: "25%", height: "25%", padding: "15px" }}
            className="image-item fade-in"
            onClick={() => handleImageClick(image.id)}
          />
        ))
      : imageData
          .filter((image) => image.category === activeCategory)
          .map((image) => (
            <img 
              key={image.id}
              src={image.src}
              alt=""
              style={{ width: "25%", height: "25%", padding: "15px" }}
              className="image-item fade-in"
              onClick={() => handleImageClick(image.id)}
            />
          ));
  };

  return (
    <div style={{ backgroundColor: "rgb(230, 255, 204)" }}>
      <div className="project-section">
        <h2 style={{ color: "RED" }}>OUR PORTFOLIO</h2>
        <hr />
        {categories.map((category) => (
          <button
            className="project-btn"
            key={category}
            onClick={() => setActiveCategory(category)}
            style={{
              backgroundColor:
                activeCategory === category ? "#db0f31" : "initial",
              color: activeCategory === category ? "white" : "initial",
              margin: "5px",
            }}
          >
            {category}
          </button>
        ))}
      </div>
      <div
        className="image-item "
        style={{
          display: "flex", 
          flexWrap: "wrap",
          justifyContent:'center',
          paddingBottom: "36px",width:'100%'
        }}
      >
        {renderImages()}
      </div>
    </div>
  );
}

export default ImageFilter;
