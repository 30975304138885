import React, { useState } from "react";
import Popup from "../Popup/Popup";
import "./Contact.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    mname: "",
    lname: "",
    email: "",
    message: "",
    number: "",
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formAction = 'https://formspree.io/f/meqypgdl';
  fetch(formAction, {
    method: 'POST',
    body: new FormData(e.target), // Assuming `e.target` is your form reference
    headers: {
      'Accept': 'application/json',
    },
  })
    .then(response => {
      if (response.ok) {
        setSubmitted(true);
        setFormData({ name: "",mname: "", lname: "", email: "", message: "", number: "" }); // Reset form data
        // Handle successful form submission
      } else {
        // Handle form submission error
      }
    })
    .catch(error => {
      // Handle error
    });

    console.log(formData);

    setFormData({ name: "", mname: "", lname: "", email: "", message: "", number: "" });

    setSubmitted(true);
  };

  const handleCloseModal = () => {
    setSubmitted(false);
  };

  return (
    <>
      <div>
        <div className="form-section">
          <h1 style={{textAlign:'center'}}>Contact Us</h1>
          <p style={{textAlign:'center'}}>We will get back to you asap!</p>
          <form onSubmit={handleSubmit}>
            <div className="form-container" style={{ display: "flex",justifyContent:'space-around' }}>
              <div className="">
                <div className="form-field">
                  <label    >First Name:</label><br></br>
                  <input
                    className="form-text"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-field">
                  <label    >Middle Name:</label><br></br>
                  <input
                    className="form-text"
                    type="text"
                    name="mname"
                    value={formData.mname}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-field">
                  <label    >Last Name:</label><br></br>
                  <input
                    className="form-text"
                    type="text"
                    name="lname"
                    value={formData.lname}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="">
              <div className="form-field">
                  <label>Enter Your Email:</label><br></br>
                  <input
                    className="form-text"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-field">
                  <label>Enter your Mobile Number:</label><br></br>
                  <input
                    className="form-text"
                    name="number"
                    value={formData.number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-field">
                  <label>Type Your Message:</label><br></br>
                  <textarea className="form-text"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div style={{display:'flex',justifyContent:'center',marginBottom:'1rem'}}>

            <button className="s-btn" type="submit">Submit</button>
            </div>
          </form>
          {submitted && <Popup onClose={handleCloseModal} />}
        </div>
      </div>
    </>
  );
};

export default ContactForm;
