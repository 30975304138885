import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
export default function Footer() {
  const scrollToService = (target) => {
    scroll.scrollTo(target, {
      smooth: true,
      duration: 500,
    });
  };
  scrollToService("/");
  scrollToService("/photomanipulation");
  scrollToService("/realestate");
  scrollToService("/cliping");
  scrollToService("/service");
  scrollToService("/contact");
  scrollToService("/scrollToService");
  scrollToService("/panorama");
  scrollToService("/privacy");
  scrollToService("/disclaimer");
  scrollToService("/sitemap");

  return (
    <>
      <div className="footer-section">
        <div class="footer">
          <div class="footer1">
            <h3 className="footer-h1">Our Address:</h3>
            <p className="footer-p">
              No. 583, Vaishnavi Building, 3rd Cross, Thambu Chetty Palya Main
              Rd, Bengaluru, Karnataka, 560016
            </p>
            <h3 className="footer-h1">Email</h3>
            <p className="footer-p">Info@zeuscrewservices.com</p>
            <h3 className="footer-h1">Skype</h3>
            <p className="footer-p">Zeus@Skype.com</p>
            <div style={{ marginTop: "2rem" }}>
              <a href="https://www.facebook.com/" target="_balnk">
                <FaFacebook
                  style={{
                    color: "white",
                    fontSize: "30px",
                    paddingRight: "20px",
                  }}
                />
              </a>
              <a href="https://www.instagram.com/" target="_balnk">
                <FaInstagram
                  style={{
                    color: "white",
                    fontSize: "30px",
                    paddingRight: "20px",
                  }}
                />
              </a>
              <a href="https://www.linkedin.com/" target="_balnk">
                <FaLinkedin style={{ color: "white", fontSize: "30px" }} />
              </a>
            </div>
          </div>
          <div class="footer2">
            <img src="./Assets/location.png" />
          </div>
          <div class="footer3">
            <h3 className="footer-h1">Useful Links</h3>
            <p className="footer-p">
              <Link to="/" class="footer-link" onClick={scrollToService}>
                Home
              </Link>
            </p>
            <p className="footer-p">
              <Link to="/about" class="footer-link" onClick={scrollToService}>
                About Us
              </Link>
            </p>
            <p className="footer-p">
              <Link to="/service" class="footer-link" onClick={scrollToService}>
                Service
              </Link>
            </p>
          </div>
          <div class="footer4">
            <h3 className="footer-h1">Services</h3>
            <p className="footer-p">
              <Link
                to="/service"
                className="footer-link"
                onClick={scrollToService}
              >
                Photo Editing
              </Link>
            </p>
            <p className="footer-p">
              <Link
                to="/photomanipulation"
                class="footer-link"
                onClick={scrollToService}
              >
                Photo Manipulation
              </Link>
            </p>
            <p className="footer-p">
              <Link
                to="/realestate"
                class="footer-link"
                onClick={scrollToService}
              >
                Real Estate Image
              </Link>
            </p>
            <p className="footer-p">
              <Link
                to="/photoillustration"
                class="footer-link"
                onClick={scrollToService}
              >
                Photo Illustratiom
              </Link>
            </p>
            <p className="footer-p">
              <Link to="/cliping" class="footer-link" onClick={scrollToService}>
                Photo Clipping
              </Link>
            </p>
            <p className="footer-p">
              <Link to="/photomasking" class="footer-link" onClick={scrollToService}>
                Photo Masking
              </Link>
            </p>
            <p className="footer-p">
              <Link to="/Panorama" class="footer-link"  onClick={scrollToService}>
                360 Panorama
              </Link>
            </p>
            <p className="footer-p">
              <Link to="/blending" class="footer-link" onClick={scrollToService}>
                HDR Blending
              </Link>
            </p>
          </div>
          <div class="footer5">
            <h3 className="footer-h1">Help</h3>
            <p className="footer-p">
              <Link to="/contact" class="footer-link" onClick={scrollToService}>
                Contact
              </Link>
            </p>
            <p className="footer-p">
              <Link to="/privacy" class="footer-link" onClick={scrollToService}>
                Privacy Policy
              </Link>
            </p>
            <p className="footer-p">
              <Link to="/disclaimer" class="footer-link" onClick={scrollToService}>
                Disclaimer
              </Link>
            </p>
            <p className="footer-p">
              <Link to='/sitemap' class="footer-link" onClick={scrollToService}>
                Site-Map
              </Link>
            </p>
          </div>
        </div>
        <div>
          <hr className="hr-line"></hr>
          <p className="footer-link1">
            @2024, Bengaluru India. Inc. All Rights Rederved
          </p>
        </div>
      </div>
    </>
  );
}
