import React from 'react'

export default function Disclaimer() {
  return (
    <>
    <div
      className="nav-service-section"
      style={{ backgroundColor: "black", color: "#FFFFFF" }}
    >
      <h1 style={{ textAlign: "center", padding: "36px 0px" }}>
        Disclaimer
      </h1>
      <p>
      Zeus is committed to upholding all privacy principles to safeguard your information. Our privacy statement outlines how your information is shared with us and how we process it securely. We utilize your Personal Data and other personally non-identifiable information collected through the Website to enhance the relevance and functionality of the Website, better understand our clients, and improve our services. Terms used in our Privacy Policy are defined in the Definitions section below.
      </p>
      <div className="nav-service-container">
        <div className="nav-service-container1" style={{paddingBottom:'36px'}}>
          <h3>1. Terms and Conditions :</h3>
          <p>
          These terms and conditions govern your use of this website (Zeus). By accessing this website, you agree to these terms and conditions in full. If you disagree with any part of these terms and conditions, you must not use this website for any commercial or personal purposes.
          </p>
          <h3>2. License to Use Website :</h3>
          <p>
          Unless otherwise stated, Zeus owns the secure, authentic, reliable, and protected intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.
          </p>
          <p>
          You may view, download for caching purposes only, and print pages or other content from the website for your personal use, subject to the restrictions set out below and elsewhere in these terms and conditions. Zeus grants full rights to potential customers to access their website in a secure manner. Our customized system securely stores your personal information and does not allow third parties to access it. 
          </p>
         
          <h3>3. Liability :</h3>
          <p>
          Zeus will not be liable in relation to the contents of, or use of, or otherwise in connection with, this website:
          </p>
          <li className="nav-service-text">
          For any indirect or consequential loss
For any business losses, loss of revenue, income, profits, or anticipated savings, loss of business relationships, reputation, or goodwill, or loss or corruption of information.
          </li>
          <p>Nothing in this legal disclaimer will limit any of our liabilities in any way that is not permitted under applicable law or exclude any of our liabilities that may not be excluded under applicable law.</p>
        </div>
      </div>
    </div>
  </>
  )
}
