import React from 'react'

export default function PhotoMasking() {
  return (
    <>
    <div
      className="nav-service-section"
      style={{ backgroundColor: "black", color: "#FFFFFF" }}
    >
      <h1 style={{ textAlign: "center", padding: "36px 0px" }}>
        Photo Masking Services
      </h1>
      <p>
      Photo masking is a digital image processing technique used to isolate certain parts of an image, effectively 'masking' areas to make them transparent, hide them, or apply effects only to specific regions. This method is essential in graphic design, photo editing, and visual content creation, particularly for tasks requiring precision, such as compositing images, creating advanced graphics, or editing photos for professional use.
      </p>
      <div className="nav-service-container">
        <div className="nav-service-container1">
          <h2>Types of Photo Masking</h2>
          <h3>1. Layer Masks :</h3>
          <p>
          Use: Allows for non-destructive editing of an image. Painting black on a layer mask hides parts of the layer, white reveals them, and gray partially shows them.<br/>
Application: Common in software like Adobe Photoshop, where it’s used to blend images or apply adjustments to specific parts without altering the original image.
          </p>
          <h3>2. Clipping Masks :</h3>
          <p>
          Use: Uses one layer to define the visibility or transparency of another layer.<br/>
Application: Often used for text effects, where the text layer is used as a mask to show only the parts of the image directly beneath the text.
          </p>
          <h3>3. Alpha Channel Masks :</h3>
          <p>
          Use: Stores the mask as an alpha channel, which can handle multiple levels of transparency more comprehensively than binary (visible or invisible) masks.<br/>
Application: Useful for complex masking tasks like semi-transparent objects or fine details like hair or fur.
          </p>
          <h3>4. Color Range Masks :</h3>
          <p>
          Use: Selects parts of an image based on color similarity, which is then used to create a mask.<br/>
Application: Great for isolating elements based on color, helpful in background removal or selective color adjustments.
          </p>
        </div>
        <div className="service-container2">
          <img className="nav-service-img" src="./Assets/photo masking.avif"></img>
        </div>
      </div>
      <h2 className="nav-service-h2">
      Common Uses of Photo Masking:
      </h2>
      <div className="nav-service-container">
        <img className="nav-service-container-images" src="./Assets/photo masking1.avif"></img>
        <div className="nav-service-texts">
          <li className="nav-service-text">
          Background Removal: Particularly for complex objects like furry animals, transparent items, or detailed outlines.<br/>
Compositing: Combining multiple images into a single image. Masking is used to seamlessly blend edges and layers.<br/>
          </li>
          <li className="nav-service-text">
          Selective Adjustments: Applying color, brightness, or contrast adjustments to specific parts of an image without affecting the whole.
Image Manipulation: Creating artistic effects or completely altering the look and context of an image.
          </li>
          <li className="nav-service-text">
          Feathering Edges: Softens the edges of a mask to blend more naturally with the background.<br/>
Adjusting Opacity: Fine-tuning the opacity of the mask can help achieve more realistic effects, especially in composite images.<br/>
Using a Pen Tablet: For intricate masking, such as hair or fur, using a pen tablet can provide better control than a mouse.
          </li>
        </div>
      </div>
      <div className="nav-service-container-last">
        <div>
          <p>
Adobe Photoshop: Widely regarded as the most powerful tool for photo masking, featuring various types of masks and techniques.<br/>
GIMP: A free, open-source alternative to Photoshop that supports basic and advanced masking functions.<br/>
Affinity Photo: Known for its non-destructive workflow, it offers advanced masking capabilities similar to Photoshop.
          </p>
          <p>
          Photo masking is a fundamental skill in digital image manipulation, offering immense flexibility in editing and creating high-quality visuals. Whether you’re a graphic designer, photographer, or hobbyist, mastering this technique can significantly enhance your ability to manipulate and improve your images.
          </p>
        </div>
        <img
          className="nav-service-container-images1"
          src="./Assets/photo masking2.avif"
        ></img>
      </div>
    </div>
  </>
  )
}
