import React from "react";

export default function PhotoManipulation() {
  return (
    <>
      <div
        className="nav-service-section"
        style={{ backgroundColor: "black", color: "#FFFFFF" }}
      >
        <h1 style={{ textAlign: "center", padding: "36px 0px" }}>
          Photo Manipulation Services
        </h1>
        <p>
          Photo manipulation is a fascinating art form that allows creators to
          blend reality with imagination, resulting in stunning and often
          surreal imagery. Whether it's subtle enhancements to improve a
          photograph's aesthetics or elaborate composites that transport viewers
          to fantastical realms, photo manipulation offers endless possibilities
          for creative expression.
        </p>
        <div className="nav-service-container">
          <div className="nav-service-container1">
            <h2>
              Here are some key aspects and techniques involved in photo
              manipulation:
            </h2>
            <h3>1. Compositing :</h3>
            <p>
              Compositing involves combining multiple images to create a
              cohesive and visually compelling final piece. This could include
              merging elements from different photographs, such as landscapes,
              objects, or people, to construct a new scene.
            </p>
            <h3>2. Color Correction and Grading :</h3>
            <p>
              Adjusting colors can significantly impact the mood and atmosphere
              of an image. Photo manipulators often employ techniques like color
              correction, color grading, and selective color adjustments to
              enhance or alter the color palette of their compositions.
            </p>
            <h3>3. Retouching :</h3>
            <p>
              Retouching involves refining and enhancing elements within a
              photograph to improve its overall quality. This can range from
              removing imperfections and blemishes to reshaping objects or
              individuals within the image.
            </p>
            <h3>4. Digital Painting :</h3>
            <p>
              Some photo manipulators incorporate digital painting techniques to
              add or modify elements within a photograph. This allows for the
              creation of entirely new elements or the alteration of existing
              ones with custom textures and details.
            </p>
            <h3>5. Manipulating Light and Shadow :</h3>
            <p>
              Controlling light and shadow is crucial for creating a sense of
              depth and dimensionality in photo manipulations. Techniques such
              as dodging and burning can be used to selectively lighten or
              darken areas of an image, enhancing its overall visual impact.
            </p>
          </div>
          <div className="service-container2">
            <img
              className="nav-service-img"
              src="./Assets/photo editing3.avif"
            ></img>
          </div>
        </div>
        <h2 className="nav-service-h2">
          Special Effects:
        </h2>
        <div className="nav-service-container">
          <img
            className="nav-service-container-images"
            src="./Assets/photo editing1.avif"
          ></img>
          <div className="nav-service-texts">
            <li className="nav-service-text">
              Incorporating special effects like surreal distortions,
              atmospheric effects, or fantastical elements can transform an
              ordinary photograph into a mesmerizing work of art.
            </li>
            <li className="nav-service-text">
              These effects can be achieved through various tools and filters
              available in photo editing software.
            </li>
            <li className="nav-service-text">
              Blending Modes and Layer Masks: Understanding blending modes and
              layer masks is essential for seamlessly integrating elements from
              different images.
            </li>
            <li className="nav-service-text">
              Blending modes determine how layers interact with each other,
              while layer masks allow for precise control over which parts of an
              image are visible or hidden.
            </li>
          </div>
        </div>
        <div className="nav-service-container-last">
          <div>
            <p>
              Creative Conceptualization: At the heart of photo manipulation
              lies creative conceptualization—the ability to envision a
              compelling idea or narrative and bring it to life through imagery.
              <p>
                This involves brainstorming concepts, planning compositions, and
                executing them with precision and creativity.
              </p>
            </p>
          </div>
          <img
            className="nav-service-container-images1"
            src="./Assets/photo editing.avif"
          ></img>
        </div>
      </div>
    </>
  );
}
