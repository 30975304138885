import React from 'react'
import { useState } from "react";
import { Link } from "react-router-dom";
export default function Navbar() {
    const [isOpen, setIsOpen] = useState(true);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className='header-section'>
      <div className="header-container">
       <img style={{cursor:'pointer'}}  className="logo" src="./Assets/logo.png"></img>
        <div className="nav-items1">
          <ul className="nav-items-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/service">Services</Link>
            </li>
            <li>
              <Link to="/project">Projects</Link>
            </li>
            <li>
              <Link to ="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
        <img
          className="menu-icon"
          src="./Assets/menu-bar.png"
          onClick={toggleNavigation}
        ></img>
        {isOpen || (
          <div className="nav-items">
            <ul className="nav-items-menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/service">Services</Link>
            </li>
            <li>
              <Link to="/project">Projects</Link>
            </li>
            <li>
              <Link to ="/contact">Contact Us</Link>
            </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}
