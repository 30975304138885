// Modal.js


import './Popup.css'
// Modal.js

// Modal.js

import React from 'react';

const Modal = ({ onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="modal-overlay" onClick={handleClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          {/* Close button */}
          <button className="close-btn" onClick={onClose}>Close</button>
          {/* Modal content */}
          <h1 style={{color:'green'}}>Thank you for your submission!</h1>
        </div>
      </div>
    </div>
  );
};

export default Modal;



