import React from "react";
import "./SiteMap.css";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
export default function SitMap() {
  const scrollToService = (target) => {
    scroll.scrollTo(target, {
      smooth: true,
      duration: 500,
    });
  };
  scrollToService("/");
  scrollToService("/photomanipulation");
  scrollToService("/realestate");
  scrollToService("/cliping");
  scrollToService("/service");
  scrollToService("/contact");
  scrollToService("/scrollToService");
  scrollToService("/panorama");
  scrollToService("/privacy");
  scrollToService("/disclaimer");
  scrollToService("/sitemap");
  return (
    <>
      <div
        className="nav-service-section"
        style={{
          backgroundColor: "black",
          color: "#FFFFFF",
        }}
      >
        <h1 style={{ textAlign: "center", padding: "36px 0px" }}>Site Map</h1>
        <div className="nav-service-container">
          <div className="nav-service-container1">
            <h2 style={{ paddingBottom: "26px" }}>Our Focus service Links</h2>
          </div>
        </div>
        <div className="grid-section">
          <div className="service-links-section">
            <div className="service-links-container"></div>
            <div className="service-links-grid">
              <div className="service-links-sub-grid">
                <h3>Image Editing</h3>
              </div>

              <div style={{ textAlign: "center" }}>
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/service"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Photo Editing
                  </Link>
                </p>
                <hr className="footer-hr" style={{ margin: "5px 0" }} />
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/photomanipulation"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Photo Manipulation
                  </Link>
                </p>
                <hr className="footer-hr" style={{ margin: "5px 0" }} />
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/realestate"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Real Estate Image
                  </Link>
                </p>
                <hr className="footer-hr" style={{ margin: "5px 0" }} />
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/photoillustration"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Photo Illustratiom
                  </Link>
                </p>
                <hr className="footer-hr" style={{ margin: "5px 0" }} />
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/cliping"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Photo Clipping
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="service-links-section1">
            <div className="service-links-container1"></div>
            <div className="service-links-grid1">
              <div className="service-links-sub-grid1">
                <h3>Creative Service</h3>
              </div>

              <div style={{ textAlign: "center" }}>
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/photomasking"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Photo Masking
                  </Link>
                </p>
                <hr className="footer-hr" style={{ margin: "5px 0" }} />
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/Panorama"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    360 Panorama
                  </Link>
                </p>
                <hr className="footer-hr" style={{ margin: "5px 0" }} />
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/blending"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    HDR Blending
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="service-links-section2">
            <div className="service-links-container2"></div>
            <div className="service-links-grid2">
              <div className="service-links-sub-grid2">
                <h3>Our Service</h3>
              </div>

              <div style={{ textAlign: "center" }}>
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/contact"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Contact
                  </Link>
                </p>
                <hr className="footer-hr" style={{ margin: "5px 0" }} />
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/privacy"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Privacy Policy
                  </Link>
                </p>
                <hr className="footer-hr" style={{ margin: "5px 0" }} />
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/disclaimer"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Disclaimer
                  </Link>
                </p>
                <hr className="footer-hr" style={{ margin: "5px 0" }} />
                <p
                  className="footer-p"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                >
                  <Link
                    to="/sitemap"
                    className="servicenew-footer-link"
                    onClick={scrollToService}
                  >
                    Site-Map
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
